<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>"UniLive"개점 협약 </h2>
		<p>'유니라이브 개점 협약', Pix Technology PTE. LTD.(이하'우리측')와 너희측(너희측은 자연인, 법인 또는 기타 조직)이 약정한 쌍방의 권리와 의무를 규범화하는 법적 효력을 가진 전자 협의, 이하'본 협의'라고 한다."당신측은"나는 동의합니다"를
			선택하거나"나는 이미 이 협의를 읽고 준수하였습니다"버튼을 클릭하면 귀하가 이미 본 협의의 모든 조항을 자세히 읽고 충분히 이해하며 완전히 남김없이 받아들였음을 나타냅니다."
		</p>
		<h3> 첫 번째 계약 목적</h3>
		<p> 본 협의는 상인과 UniLive 플랫폼 간의 권리와 의무를 명확히 하고 상인의 경영 행위를 규범화하며 소비자 권익을 보호하고 UniLive 플랫폼의 건전한 발전을 추진하며 거래에서 각 측의 합법적인
			권익을 확보하는 데 목적을 둔다.
		</p>
		<h3> 두 번째 정의 </h3>
		<p>1, UniLive: 본 응용 프로그램 및 관련 서비스의 운영자가 온라인 거래 및 서비스 지원을 제공하는 플랫폼을 말합니다.</p>
		<p>
			2. 사업자: UniLive에 등록하고 상업활동을 전개하는 개인 또는 기업을 가리키며 개인사업자, 회사 및 기타 법인실체를 포함하되 이에 국한되지 않는다.
		</p>
		<p>
			3. 소비자: UniLive에서 상품이나 서비스를 구매하는 사용자를 말한다.
		</p>
		<h3> 제3조 상가 입주</h3>
		<p>
			1. 등록요구: 상인은 반드시 UniLive에 진실하고 정확한 개인 또는 기업 정보를 기입해야 한다. 여기에는 성명, 연락처, 영업허가증, 세무등록증 등이 포함된다.사업자는 등록 정보가 적시에
			업데이트되도록 해야 하며, 정보가 변경되면 48시간 이내에 업데이트해야 한다.
		</p>
		<p>2, 승인 프로세스: UniLive는 일반적으로 3~5일 (영업일 기준) 동안 사업자가 제출한 정보를 검토합니다.심사가 통과되면 상인은 확인 통지를 받고 개점 기능이 정식으로 활성화된다.
		</p>
		<p>3, 점포 설치: 상인은 UniLive에 점포를 설치해야 하며, 점포 명칭, 로고, 소개, 상품 분류 등을 포함하여 정보가 플랫폼 규정에 부합하도록 확보해야 한다.
		</p>
		<h3> 제4조 사업자의 권리와 의무 </h3>
		<p>
			1. UniLive에 상품 정보를 게시하고 판매하며 플랫폼에서 제공하는 각종 서비스를 누린다.
		</p>
		<p>2、UniLive의 판촉활동과 마케팅에 참여하여 노출률을 높인다.</p>
		<p>3, 영업 데이터, 사용자 피드백 및 시장 분석 보고서를 수집하여 경영 전략을 최적화합니다.</p>
		<p>4, 상품 합규성: 판매한 상품의 합법성, 진실성과 합규성을 확보하고 가짜 저질 제품을 판매해서는 안 된다.</p>
		<p>5, 정보 업데이트: 정기적으로 상품 정보를 업데이트하여 가격, 재고, 배송 정보를 정확하고 명확하게 확보합니다.</p>
		<p>6, 플랫폼 규칙 준수: UniLive의 각종 관리 규정과 운영 규범을 준수하고 플랫폼 교육에 참여하여 경영 능력을 향상시킵니다.</p>
		<h3> 제5조 UniLive의 권리와 의무 </h3>
		<p>1. 상인이 발표한 정보를 심사하고 관리하여 정보의 준법성을 확보한다.</p>
		<p>2. 계약을 위반한 상인에게 상응하는 조치를 취한다. 경고, 일시정지 또는 그 점포를 법률소송까지 포함하되 이에 국한되지 않는다. </p>
		<p>3. 시장 상황과 법률, 법규의 변화에 따라 정기적으로 플랫폼 정책을 갱신하고 상인은 적시에 이해하고 준수해야 한다.</p>
		<p>4, 안전하고 안정적인 거래 플랫폼을 제공하여 상인과 소비자의 거래 안전을 보장하고 사기 및 기타 위법 행위를 방지한다.</p>
		<p>5, 기업의 문제와 피드백을 신속하게 처리하고 필요한 기술 지원과 교육을 제공합니다. </p>
		<p>6, 정기적으로 상인에게 경영 데이터 분석, 시장 추세 보고 및 보급 건의를 제공하여 상인이 실적을 향상시킬 수 있도록 돕는다.</p>
		<h3> 제6조 위약책임 </h3>
		<p>
			1. 만약 상인이 본 협의의 어떠한 조항을 위반할 경우, UniLive는 상인에게 시정을 요구할 권리가 있으며, 상황의 경중에 따라 상응하는 징벌 조치를 취할 권리가 있으며, 점포 등급 인하, 상품 출시
			제한, 서비스 종료 등을 포함하되 이에 국한되지 않는다.
		</p>
		<p>2、상인의 원인으로 인한 소비자 신고는 상인이 상응하는 법적 책임과 배상 책임을 져야 한다.</p>
		<p>3. 만약 UniLive가 협의의 약정에 따라 서비스를 제공하지 못할 경우 상인은 UniLive에 손해배상을 요구할 수 있으며 구체적인 배상금액은 쌍방이 협상하여 확정한다.</p>
		<h3> 제7조 계약의 변경 및 해제 </h3>
		<p>1. 본 협의의 수정 또는 변경은 쌍방의 합의를 거쳐야 를 해제할 수 있다.</p> 
		<p>2. uniLive는 법률, 법규, 시장 변화 등 요소에 따라 정기적으로 협의를 수정하고 신속하게 상인에게 통지할 권리가 있다. </p>
		<p>3. 상인이 본 협의의 해제를 원하면 7일 전에 UniLive에 서면으로 통지하고 해제 전에 미완성 주문과 소비자 고소를 잘 처리해야 한다. </p>
		<p>4, UniLive는 상인의 심각한 위약 행위를 발견했을 때 본 협의를 즉시 해제할 권리가 있으며 미리 통지할 필요가 없습니다.</p>
		<h3> 제8조 기타 조항</h3>
		<p>1. 비밀유지조항: 쌍방은 협의리행과정에서 알게 된 상업비밀 및 기타 정보를 비밀로 해야 하며 제3자에게 공개해서는 안된다.
		</p>
		<p>2. 불가항력: 불가항력 요소(예를 들어 자연재해, 전쟁, 정책 변화 등)로 인해 협의를 이행하지 못할 경우 쌍방은 위약 책임을 지지 않는다.
		</p>

	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>